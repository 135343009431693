import React from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import AboutPage from "pages/about"

export const Head = () => (
  <SEO
    title="品牌故事"
    description="「衣庫國際服裝有限公司」成立於2015年，我們致力於為台灣服裝市場提供多元化、高性價比的選擇，並持續推動產業創新。"
    pathname="/about"
  />
)

export default () => (
  <Layout>
    <AboutPage />
  </Layout>
)
