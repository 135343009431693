import React from "react"
import Slider from "react-slick";
import { StaticImage } from "gatsby-plugin-image"
import BizBanner from "components/bizBanner"
import ContactFormBanner from "components/contactFormBanner"
import imgAbout from "assets/asset-about-1.png"
import imgAboutM from "assets/asset-about-1-m.png"
import imgOrgSlogan from "assets/asset-about-2-1.png"
import imgOrg from "assets/asset-about-2-2.png"
import imgOrgM from "assets/asset-about-2-2-m.png"
import imgCOA1 from "assets/asset-coa-1.png"
import imgCOA2 from "assets/asset-coa-2.png"
import imgCOA3 from "assets/asset-coa-3.png"
import imgCOA4 from "assets/asset-coa-4.png"
import imgCOA5 from "assets/asset-coa-5.png"
import imgCOA6 from "assets/asset-coa-6.png"
import "./styles.scss"

const sliderSettings = {
  infinite: true,
  autoplay: true,
  slidesToShow: 5,
  slidesToScroll: 1,
}

const sliderSettingsM = {
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
}

export default () => (
  <div className="about-page-container">
    {/* BANNER 區塊 */}
    <StaticImage
      className="global-hidden-mobile"
      src="../../images/img-about-banner.png"
      alt="品牌故事"
      layout="fullWidth"
      quality={100}
      breakpoints={[900, 1680, 3000]}
      placeholder="none"
      backgroundColor="#FFF"
    />
    <StaticImage
      className="global-hidden-desktop"
      src="../../images/img-about-banner-m.png"
      alt="品牌故事"
      layout="fullWidth"
      quality={100}
      breakpoints={[480, 1680]}
      placeholder="none"
      backgroundColor="#FFF"
    />
    {/* 關於我們 區塊 */}
    <div className="about-detail-container">
      <img className="img-about global-hidden-mobile" src={imgAbout} alt="關於我們" />
      <img className="img-about global-hidden-desktop" src={imgAboutM} alt="關於我們" />
    </div>
    {/* 組織架構 區塊 */}
    <div className="org-detail-header">
      <img className="org-detail-title global-hidden-mobile" src={imgOrg} alt="Organization" />
      <img className="org-detail-title-m global-hidden-desktop" src={imgOrgM} alt="Organization" />
      <img className="org-detail-slogan" src={imgOrgSlogan} alt="組織架構" />
    </div>
    <div className="org-detail-container">
      <div className="org-detail-body">
        <StaticImage
          className="global-hidden-mobile"
          src="../../images/img-org-structure.png"
          alt=""
          quality={100}
        />
        <StaticImage
          className="global-hidden-desktop"
          src="../../images/img-org-structure-m.png"
          alt=""
          layout="fullWidth"
          quality={100}
        />
        <StaticImage
          className="global-hidden-mobile"
          src="../../images/img-org-history.png"
          alt=""
          quality={100}
        />
        <StaticImage
          className="global-hidden-desktop"
          src="../../images/img-org-history-m.png"
          alt=""
          layout="fullWidth"
          quality={100}
        />
      </div>
    </div>
    {/* 經營理念 區塊 */}
    <BizBanner />
    {/* 公益活動 區塊 */}
    <div className="charity-container global-hidden-mobile">
      <StaticImage
        src="../../images/img-charity-bg.png"
        alt=""
        layout="fullWidth"
        quality={100}
      />
      <Slider className="coa-slider" {...sliderSettings}>
        <div>
          <img className="coa-image" src={imgCOA1} alt="感謝狀" />
        </div>
        <div>
          <img className="coa-image" src={imgCOA2} alt="感謝狀" />
        </div>
        <div>
          <img className="coa-image" src={imgCOA3} alt="感謝狀" />
        </div>
        <div>
          <img className="coa-image" src={imgCOA4} alt="感謝狀" />
        </div>
        <div>
          <img className="coa-image" src={imgCOA5} alt="感謝狀" />
        </div>
        <div>
          <img className="coa-image" src={imgCOA6} alt="感謝狀" />
        </div>
      </Slider>
    </div>
    <div className="charity-container global-hidden-desktop">
      <StaticImage
        src="../../images/img-charity-bg-m.png"
        alt=""
        layout="fullWidth"
        quality={100}
      />
      <Slider className="coa-slider" {...sliderSettingsM}>
        <div>
          <img className="coa-image" src={imgCOA1} alt="感謝狀" />
        </div>
        <div>
          <img className="coa-image" src={imgCOA2} alt="感謝狀" />
        </div>
        <div>
          <img className="coa-image" src={imgCOA3} alt="感謝狀" />
        </div>
        <div>
          <img className="coa-image" src={imgCOA4} alt="感謝狀" />
        </div>
        <div>
          <img className="coa-image" src={imgCOA5} alt="感謝狀" />
        </div>
        <div>
          <img className="coa-image" src={imgCOA6} alt="感謝狀" />
        </div>
      </Slider>
    </div>
    {/* 聯絡我們 區塊 */}
    <ContactFormBanner />
  </div>
)
